.Hero{
display: flex;
justify-content: space-between;
}
/* start style left side */
.left{
  padding: 2rem;
  padding-top: 1.5rem;
  flex:3;
  display: flex;
  gap: 2rem;
  flex-direction: column;
}
.the-best-ad{
  margin-top: 4rem;
  background-color: var(--the-best-ad);
  border-radius: 4rem;
  -webkit-border-radius: 4rem;
  -moz-border-radius: 4rem;
  -ms-border-radius: 4rem;
  -o-border-radius: 4rem;
  width: fit-content;
  padding:20px 13px;
  color: white;
  text-transform: uppercase;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: -1;
}

.the-best-ad>div{
  position: absolute;
  background-color: var(--orange);
  width: 5.4rem;
  height: 80%;
  border-radius: 3rem;
  -webkit-border-radius: 3rem;
  -moz-border-radius: 3rem;
  -ms-border-radius: 3rem;
  -o-border-radius: 3rem;
  left:8px;
  z-index: -1;
}
.hero-text{
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  text-transform: uppercase;
  font-size: 4.5rem;
  font-weight: bold;
  color: white;
  text-overflow: inherit;
}
.hero-text>div:nth-child(3){
  font-size: 1rem;
  font-weight: 200;
  text-transform: none;
  letter-spacing:1px;
  width: 80%;
}
.figures{
  display: flex;
  gap: 2rem;
}
.figures>div{
  display: flex;
  flex-direction: column;
}
.figures>div>span:nth-of-type(1){
  color: white;
font-size: 2rem;
}
.figures>div>span:nth-of-type(2){
color: var(--gray);
text-transform: uppercase;
}

.hero-buttons{
  display: flex;
  gap: 1rem;
  font-weight: normal;
}
.hero-buttons>:nth-child(1){
color: white;
background-color: var(--orange);
width: 8rem;
}
.hero-buttons>:nth-child(2){
color: white;
background-color: transparent;
width: 8rem;
border: 2px solid var(--orange);
}
/* end style left side */

/* start style right side */
.right{
  flex:1;
  position: relative;
  background-color: var(--orange);
}
.right>.btn {
  position: absolute;
  right: 3rem;
  top: 2rem;
  color: black;
}
.heart-rate{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: var(--darkGrey);
  width: fit-content;
  padding: 1rem;
  align-items: start;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  position:absolute;
  right: 4rem;
  top: 7rem;
}
.heart-rate>img{
  width: 2rem;
}
.heart-rate>:nth-child(2){
color: var(--gray);
}
.heart-rate>:nth-child(3){
color: white;
font-size: 1.5rem;
}

.hero-image{
  position: absolute;
  top: 10rem;
  right: 8rem;
  width: 23rem;
}
.hero-image-back{
  position: absolute;
  top: 4rem;
  right: 20rem;
  z-index: -1;
  width: 15rem;
}
.calories{
  display: flex;
  gap: 2rem;
  background-color: var(--caloryCard);
  border-radius:5px ;
  -webkit-border-radius:5px ;
  -moz-border-radius:5px ;
  -ms-border-radius:5px ;
  -o-border-radius:5px ;
  padding: 1rem;
  width: fit-content;
  position: absolute;
  top: 32rem;
  right: 28rem;
}
.calories>img{
width: 3rem;
}
.calories > div{
display: flex;
flex-direction: column;
justify-content: space-between;
}
.calories > div >span{
width: 8rem;
}
.calories > div >:nth-child(1){
color: var(--gray);
}
.calories > div >:nth-child(2){
color: white;
font-size: 1.5rem;
}
.blur-h{
  width: 22rem;
  height: 30rem;
  left: 0;
  
}

@media screen and (max-width: 768px) {
  
.Hero{
  flex-direction: column;
}
.the-best-ad{
  margin-top: 0;
  font-size: small;
  align-self: center;
  transform: scale(0.8);
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
}
.hero-text{
  font-size: xx-large;
  align-items: center;
  justify-content: center;
}
.hero-text>div:nth-child(3){
  width: 66%;
font-weight: 200;
letter-spacing: 1px;
text-align: center;
}
.hero-buttons{
  justify-content: center;
}
.figures>div>span:nth-of-type(1){
  font-size: 24px;
  text-align: center;
}
.figures>div>span:nth-of-type(2){
  font-size: 12px;
  text-align: center;
}
.figures{
  margin-left: 1rem;
}
.right{
position: relative;
background: none;
}
.heart-rate{
left: 1rem;
top: 2rem;
}
.calories{
position: relative;
top: 5rem;
left: 2rem;
}
.calories>img{
  width: 2rem;
  }
  
.calories > div >:nth-child(2) {
color: white;
font-size: 1rem;
}
.hero-image{
position: relative;
width: 15rem;
left: 7rem;
top: 4rem;
align-self: center;
}
.hero-image-back{
  width: 15rem;
  left: 2rem;
  top: 0rem;
}
}
.program{
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0.2rem;
}
.programs-header{
  display: flex;
  gap: 5rem;
  font-size: 3rem;
  justify-content: center;
  color: white;
  text-transform: uppercase;
  font-style: italic;
  font-weight: bold;
}
.programs-catigories{
  display: flex;
  gap: 1rem;
}
.catigory{
  display: flex;
  flex-direction: column;
  background-color: gray;
  padding: 2rem;
  gap: 1rem;
  color: white;
  justify-content: space-between;
}
.catigory>:nth-child(1){
  width: 2rem;
  height: 2rem;
  fill: white;
}
.catigory>:nth-child(2){
font-size: 1rem;
font-weight: bold;
}
.catigory>:nth-child(3){
font-size: 0.9rem;
line-height: 25px;
}
.join-now{
  display: flex;
  align-items: center;
  gap: 2rem;
}
.join-now>img{
width: 1rem;
}
.catigory:hover{
background:var(--planCard);
cursor: pointer;
}

@media screen  and (max-width: 768px) {
  .heading-text{
    flex-direction: column;
    gap: 1rem;
    font-size: x-large;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
  }
  .programs-catigories{
    flex-direction: column;
  }
}
.Header{
  display: flex;
  justify-content: space-between;
}
.logo{
  width:10rem;
  height: 3rem;
}
.header-menu{
  list-style: none;
  display: flex;
  gap: 2rem;
  color: white;
}
.header-menu>li:hover{
  cursor: pointer;
color: var(--orange);
}
.fixed{
  position: fixed;
  right: 2rem;
  z-index: 99;
}
@media screen  and (max-width: 768px) {

  .header-menu{
    flex-direction: column;
    background-color: var(--appColor);
    padding: 2rem;
    position: fixed;
    right: 0rem;
    top: -16px;
    z-index: 99;
  }
}
